<template>
  <div class="dataCenterContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>
      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0 0 40px">
          人力资源数据中心
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="ptDesc center">
        <div class="ptDescContent">
          <div class="ptDescLeft">
            <img src="@/assets/product/dataCenter/dataPt.png" alt="" style="width:450px;height:100%;border-radius: 10px 0 0 10px;">
            <div class="ptDescLeftMask"></div>
          </div>
          <div class="ptDescRigth">
            建立人力资源数据中心，通过科学的数据采集和数据维护机制，多渠道采集、整合、维护和存储与教职工相关的人事、教学、科研等360度全方位数据信息，发挥数据对业务的支持、对服务的支持、对决策的支持，作为各业务子系统之间数据共享，以及教职工绩效管理和行为分析的基础。
          </div>
        </div>

        <h4 class="descH4">人力资源数据中心的价值优势</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div v-for="(item,index) in valueList" :key="index" class="valueItem">
            <div class="valueItemMask"></div>
            <img :src="item.src" alt="" class="valueLeftRed">
            <img :src="item.hoverSrc" alt="" class="valueLeftHover">
            <div class="valueRight">
              <div class="valueTitle">
                {{item.title}}
              </div>
              <div class="valueText">
                {{item.content}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mainAble center">
        <h4 class="descH4">主要功能</h4>
        <span class="line"></span>
        <img src="@/assets/product/dataCenter/gnt.png" alt="" style="width:1200px;padding-top:60px">
      </div>
      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductDataCenter',
  data () {
    return {
      valueList: [
        { title: '数据工具化', content: '全域教职工信息库，便捷查询统计分析', src: require('@/assets/product/dataCenter/inquiryStatistics.png'), hoverSrc: require('@/assets/product/dataCenter/shujuhua.png') },
        { title: '数据业务化', content: '数据自动填报，业务采集数据，数据优化业务', src: require('@/assets/product/dataCenter/businessSupport.png'), hoverSrc: require('@/assets/product/dataCenter/ywh.png') },
        { title: '数据决策化', content: '数据挖掘与分析，构建数据模型', src: require('@/assets/product/dataCenter/shujufs.png'), hoverSrc: require('@/assets/product/dataCenter/sjjc.png') }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moDataCenter')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
.dataCenterContainer {
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/product/dataCenter/banner.png") center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.ptDesc{
  width: 100%;
height: 890px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.ptDescContent{
  width: 1200px;
  height: 300px;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
  margin: 40px 0;
  border-radius: 10px;
}
.ptDescLeft{
  position: relative;
  top: 0;
  left: 0;
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.ptDescLeftMask{
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 48, 0.15);
}
.ptDescRigth{
  width: 750px;
  height: 100%;
  background: url('~@/assets/product/productPt/ptBj.jpg') center no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 10px 10px 0px;
  padding: 70px 80px;
  text-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  font-size: 18px;
  color: #222;
  font-weight: 400;
  line-height: 40px;
}

.valueContent{
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 384px;
  height: 272px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 40px;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:last-child{
  margin-right: 0px;
}
.valueLeftRed{
  width: 68px;
  z-index: 100;
}
.valueLeftHover{
  width: 68px;
  display: none;
  z-index: 100;
}
.valueRight{
  z-index: 100;
  margin-left: 24px;
  .valueTitle{
    font-size: 24px;
    font-weight: 500;
    color: #222222;
    margin: 20px 0;
    text-align: center;
  }
  .valueText{
    font-size: 16px;
    font-weight: 400;
    color: #444444;
    line-height: 24px;
  }
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
    background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueTitle{
    color: #fff;
  }
  .valueText{
    color: #fff;
  }
  .valueLeftRed{
    display: none;
  }
  .valueLeftHover{
    display:block ;
  }
}

.mainAble{
width: 100%;
height: 685px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

</style>
